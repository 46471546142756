<template>
  <b-card class="mb-0">
    <h2>{{ $t('service.main_informations.title') }}</h2>
    <validation-observer v-if="product" ref="mainInformationFormRef" tag="form" @submit.prevent="onSubmit">
      <b-row class="mt-2">
        <b-col class="d-flex align-items-stretch flex-column mb-md-2" cols="12" md="6">
          <b-form-group :label="$t('service.category')" class="validation-required mb-0" label-for="serviceCategory">
            <div class="border rounded wrapper-service-category">
              <categories-tree
                :current-parent="product.mainCategory.id ? [product.mainCategory.id] : []"
                only-active
                @category-selected="
                  event => {
                    if (event[0]) {
                      // Has selected category
                      product.mainCategory = event[0]
                      fetchCategories(event[0].id)
                    } else {
                      product.mainCategory.id = null
                    }
                    // TODO: dynamic lang
                    nameSelected = { en: '', fr: '' }
                    product.name = product._reference.name
                  }
                "
              />
            </div>
          </b-form-group>
        </b-col>

        <!--NAME-->
        <b-col class="d-flex align-items-stretch flex-column my-2" cols="12" md="6">
          <b-card :title="$t('service.name')" class="border mb-0 h-100">
            <!--OTHER CUSTOM NAME-->
            <b-overlay :show="!isLoaded" :variant="$store.state.appConfig.layout.skin === 'dark' ? 'transparent' : undefined">
              <input-lang
                :active-language="currentLanguage"
                :default-locale="appLanguage"
                class="m-0"
                @switch-lang="currentLanguage = $event"
              >
                <b-form-select
                  id="serviceName"
                  v-model="nameSelected"
                  :options="categories._attributes.items[`cat${product.mainCategory.id}`] || []"
                  :placeholder="$t('service.name')"
                  :text-field="`name.${currentLanguage}`"
                  size="md"
                  value-field="name"
                  :disabled="!product.mainCategory.id || $route.name === 'productShow' || $route.name === 'operatorProductShow'"
                  @change="!nameSelected[currentLanguage] ? (product.name = product._reference.name) : (product.name = _cloneDeep($event))"
                >
                  <template #first>
                    <!-- TODO: dynamic lang init -->
                    <b-form-select-option :value="{ en: '', fr: '' }"> {{ $t('service.custom_name') }} </b-form-select-option>
                  </template>
                </b-form-select>
                <validation-provider v-show="nameSelected[currentLanguage] === ''" #default="{}" :name="$t('service.custom_name')" rules="required">
                  <b-form-group>
                    <!-- TODO: refacto it -->
                    <b-form-input
                      v-model="product.name[currentLanguage]"
                      :name="$t('service.custom_name')"
                      :placeholder="$t('service.custom_name')"
                      :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
                      :required="!product.name[appLanguage]"
                      :state="product._errors.customNameRequired && !product.name[appLanguage] ? false : null"
                      @input="delete product._errors.customNameRequired"
                    />
                    <small v-if="product._errors.customNameRequired" class="text-danger">
                      {{ $t('common.error.default_language_required') }} ({{ appLanguage }})
                    </small>
                  </b-form-group>
                </validation-provider>
              </input-lang>
            </b-overlay>
          </b-card>
        </b-col>

        <!--Visible-->
        <b-col class="mb-md-2" cols="12" md="6">
          <b-form-group :label="$t('service.main_informations.visible_label')" label-for="productVisible">
            <b-overlay :show="!isLoaded" :variant="$store.state.appConfig.layout.skin === 'dark' ? 'transparent' : undefined">
              <v-select
                v-model="product.visible"
                :clearable="false"
                :options="[
                  { value: true, text: 'service.main_informations.visible' },
                  { value: false, text: 'service.main_informations.invisible' },
                ]"
                :get-option-label="opt => $t(opt.text)"
                :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
                :placeholder="$t('service.main_informations.visible_label')"
                :reduce="option => option.value"
                input-id="productVisible"
                label="text"
              />
            </b-overlay>
          </b-form-group>
        </b-col>

        <!--REFERENCE-->
        <b-col class="mb-md-2" cols="12" md="6">
          <app-input
            id="internalReference"
            v-model="product.baseReference"
            :label="$t('service.main_informations.internal_reference')"
            :overlay="!isLoaded"
            :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
            rules="required"
          />
        </b-col>
      </b-row>

      <b-card :title="$tc('common.setting', 2)" class="border">
        <enum-radio
          id="productTypology"
          v-model="product.typology"
          :label="$t('service.type')"
          :overlay="!isLoaded"
          enum-class="ProductTypology"
          :disabled="$route.name === 'productEdit' || $route.name === 'productShow' || $route.name === 'operatorProductShow'"
        />
      </b-card>
      <footer-form-service v-if="$route.name === 'productShow' || $route.name === 'operatorProductShow'" next :disabled="saveLoader" @click:next="$emit('next-tab')" />
      <footer-form-service v-else save save-next :disabled="saveLoader" @click:save-next="nextRedirection = true" />
    </validation-observer>
  </b-card>
</template>

<script>
import { defineComponent, inject, reactive, ref } from '@vue/composition-api'

import CategoriesCollection from '@/collections/CategoriesCollection'
import CategoriesTree from '@/views/category/CategoriesTree.vue'
import EnumRadio from '@/components/EnumRadio.vue'
import InputLang from '@/views/product/tab/InputLang.vue'
import FooterFormService from '../component/FooterFormService.vue'

export default defineComponent({
  name: 'MainInformation',

  components: {
    CategoriesTree,
    EnumRadio,
    InputLang,
    FooterFormService,
  },

  setup(_props, ctx) {
    const { toaster, $i18n, $set, $store } = ctx.root
    const $emit = ctx.emit

    const route = ref({})
    const product = inject('product')
    const appLanguage = ref(localStorage.getItem('appLang'))
    const currentLanguage = ref($store.state.auth.defaultLang)
    const nextRedirection = ref(false)
    const saveLoader = ref(false)
    const isLoaded = ref(ctx.root.$route.name === 'serviceAdd')
    // TODO: Dynamic lang init
    const nameSelected = ref({ en: '', fr: '' })

    const categories = reactive(new CategoriesCollection())
    const fetchCategories = async id => {
      categories.fetchProductName(id, { getAllItems: true })
    }

    // ref template
    const mainInformationFormRef = ref(null)

    const onSubmit = async () => {
      let validForm = await mainInformationFormRef.value.validate()
      if (!product.value.name[appLanguage.value]) {
        validForm = false
        $set(product.value._errors, 'customNameRequired', true)
      }
      if (product.value.mainCategory.id && validForm) {
        const action = route.value.name === 'serviceAdd' ? 'post' : 'patch'
        saveLoader.value = true
        await product.value[action]().finally(() => {
          saveLoader.value = false
        })
        if (action === 'post') {
          // TODO dynamic 'additional-information (?) => if necessary in the future
          $emit('next-tab', !nextRedirection.value ? 'main-information' : 'additional-information')
        } else if (nextRedirection.value) {
          $emit('next-tab')
          nextRedirection.value = false
        }
      } else {
        toaster($i18n.t('alert.fill_required_fields'), 'danger', 'AlertTriangleIcon')
      }
    }

    return {
      route,
      product,
      categories,
      fetchCategories,
      appLanguage,
      currentLanguage,
      nameSelected,
      nextRedirection,
      saveLoader,
      isLoaded,
      mainInformationFormRef,
      onSubmit,
    }
  },

  watch: {
    '$route.name': {
      handler(routeName) {
        this.isLoaded = this.$route.name === 'serviceAdd'
        this.route = this.$route
        if (routeName === 'productEdit' || routeName === 'productShow' || routeName === 'operatorProductShow') {
          this.product.fetch(this.$route.params.service_id).then(() => {
            this.isLoaded = true
          })
        }
      },
      immediate: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.wrapper-service-category {
  max-height: 233px;
  overflow: auto;
}
</style>
